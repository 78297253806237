import styled from "styled-components";

export const GeneralContainer = styled.section`
    width: 100%;
    margin: 0 auto;
    // padding: 20px 60px; 
    // display: flex;
    // justify-content: space-between;
    // align-items: start;
    // @media screen and (max-width: 792px){
    //     flex-direction: column;
    //     padding: 20px 10px; 
    
    }
    `;
