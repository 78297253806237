import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavigationContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  @media screen and (max-width: 792px){
    height: 60px;
    padding:10px 20px;
    margin-bottom: 20px;
  }
`;

export const BannerContainer = styled.h4`
  width: 100%;
  height: 30px;
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -3.5%;
  text-align: center;
  background-color: #f0f0f0;
  z-index: 100;
  `;

export const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
  padding: 25px;
  @media screen and (max-width: 792px){
    width: 50px;
    padding: 0;
    margin-bottom: 20px;
`;

export const NavLinks = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 792px){
    width:80%;
  }

`;
export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
`;


export const HeaderContainer = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    bacground-image: ${({ imageUrl }) => `url(${imageUrl})`};
    `
export const HeaderImg = styled.img`
    opacity: 0.5;
    width: 100%;
    height: 200px;
    top: 0;
    object-fit: cover; /* Ensures the image covers the area and maintains its aspect ratio */
    object-position: 0 9%; /* Aligns the image to show the top part */
`;


export const TitleContainer = styled.div`
position: absolute;
  top: 10%;
  right: 5%;
  
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  // background-color: white;
  // opacity: 0.7;
  border-radius: 10px;
  padding: 15px;
  &:hover {
    background-color: white; 
    opacity:0.9;
    // transform: scale(1.1);
    transition: transform 2s cubic-bezier(0.25, 0.45, 0.45, 0.95);

  }

  @media screen and (max-width: 792px){
      top: 10%;
      font-size: 25px;
      left: 50%;
      transform: translate(-50%);
      background-color: white; 
      opacity:0.9;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
  }
     
`;

export const Title = styled.h1`
  opacity: 1;
  
  // transform: translate(-50%, -50%);
  font-size: 45px;
  color: black;
  font-weight: 700;
  text-align: center;
  margin: 0;

  /* Text outline effect using text-shadow */
  text-shadow: 
    -3px -3px 0 white,  
    3px -3px 0 white,  
    -3px 3px 0 white,  
    3px 3px 0 white;
    @media screen and (max-width: 792px){
      line-height: 1.1;
      font-size: 25px;
     
    }
`;
