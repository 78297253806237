import styled from "styled-components";

export const GeneralContainer = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 20px 60px; 
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media screen and (max-width: 792px){
    flex-direction: column;
    padding: 20px 10px; 

  }
`;

export const LeftContainer = styled.section`
    width: 70%;
    margin: 0 auto;
    padding: 20px 10px; 
    @media screen and (max-width: 792px){
        width: 100%;
    }
    `;

export const RightContainer = styled.section`
    position: sticky;
    top: 20px; /* Adjust the distance from the top */
    width: 30%;
    margin: 0 auto;
    padding: 20px 10px; 
    h2 {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 0px;
        font-size: 22px;
        color: white;
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        background-color: grey;
        text-transform: uppercase;

       
    }
        @media screen and (max-width: 792px){
            position: relative;
            width: 100%;
            // order: -1;
            top: 0;
        }
    `;

