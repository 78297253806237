import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    background-color: #f8f8f8;
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 5px 60px;
    font-size: 14px;
    color: #4a4a4a;
    text-transform: uppercase;
    text-shadow: 
    -3px -3px 0 white,  
    3px -3px 0 white,  
    -3px 3px 0 white,  
    3px 3px 0 white;
    font-weight: bold;

    h5 {
        margin: 1px;
    }

    @media screen and (max-width: 792px){
        font-size: 10px;
        padding: 5px 20px;

    }
`