import { useNavigate } from "react-router-dom";
import { BackgroundImage, Body, DirectoryItemContainer } from "./directory-item.styles";

const DirectoryItem = ({ category, size }) => {
  const { title, imageUrl, route } = category;
  const navigate = useNavigate();

  const onNavigateHandler = () => {
    navigate(route); // Navigate to the route when clicked
  };

  return (
    <DirectoryItemContainer size={size}> {/* Pass the size prop directly */}
      <BackgroundImage
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        <Body onClick={onNavigateHandler}> {/* Attach the click handler here */}
          <h2>{title}</h2>
        </Body>
      </BackgroundImage>
    </DirectoryItemContainer>
  );
};

export default DirectoryItem;
