import React from 'react';
import { FooterContainer } from './footer.style';

const Footer = () => {
    return (
        <FooterContainer>
           <h5>Cosmo Tehno Install</h5> 
           <h5>Autorizatie IGSU: 546543243</h5> 
           <h5>Licenta IGPR: jkhaslkjdhal56488</h5> 
           <h5>&copy;2024 COSMO - All rights reserved</h5> 
        </FooterContainer>
    );
};

export default Footer;