import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from '../footer/footer.component';
import Navigation from '../navigation/navigation.comoponent';
import { GeneralContainer } from './BaseLayout.style';

import { CategoriesContext } from "../../contexts/categories.context";
const BaseLayout = () => {

    return (
        <>
            <Navigation />
            <GeneralContainer>
               <Outlet />

            </GeneralContainer>
            <Footer />
        </>
    )
}

export default BaseLayout