import styled from "styled-components";
export const BackgroundImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    bacground-image: ${({ imageUrl }) => `url(${imageUrl})`};
    @media screen and (max-width: 792px){
        align-items: end;
        // padding-bottom: 20px;
        border: none;
    }
`
export const TitleContainer = styled.div`
        
        `

export const Body = styled.div`
    max-height: 50%;
    // width: 100%;
    margin: 0;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: white;
    opacity: 0.8;
    border-radius: 10px;
    max-width: 70%;

        h2 {
            font-weight: bold;
            margin: 0 6px 0;
            font-size: 22px;
            color: #4a4a4a;
            text-transform: uppercase;
            text-shadow: 
            -3px -3px 0 white,  
            3px -3px 0 white,  
            -3px 3px 0 white,  
            3px 3px 0 white;

        }

        p {
            font-weight: lighter;
            font-size: 16px;
        }
    

    @media screen and (max-width: 792px){
        padding: 5px 15px;
        border: none;
        max-width: 100%;
        text-align: center;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        opacity: 0.9;

    }  

`


export const DirectoryItemContainer = styled.div`
    flex-basis: ${props => props.size || '30%'};  /* This controls the minimum width, you can adjust this as per your need */
    height: 300px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin: 0 7.5px 15px;
    overflow: hidden; 
    border-radius: 10px;

    &:first-child {
        margin-right: 7.5px;
        }
    
    &:last-child {
        margin-left: 7.5px;
        }

    &:hover {
        cursor: pointer;
    
        & ${BackgroundImage} {
          transform: scale(1.1);
          transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }
    
        & ${Body} {
          opacity: 0.9;
        }
    }
    @media screen and (max-width: 792px){
        flex-basis: 100% !important;
    }

    `






