import { Routes, Route } from "react-router-dom";

import Home from "./routes/home/home.component";
import Authentication from "./routes/authentication/authentication.component";
import Shop from "./routes/shop/shop.component";
import Checkout from "./routes/checkout/checkout.component";
import { GlobalStyle } from "./global.styles";
import UnderConstruction from "./routes/under-construction/under-construction.component";
import BaseLayout from "./routes/base-layout/BaseLayout.component";

const App = () => {
  return (
    <>
    <GlobalStyle/>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route path="/" index element={< UnderConstruction/>} />
          <Route path="home" element={<Home />} />
          <Route path="shop/*" element={<Shop />} />
          <Route path="auth" element={<Authentication />} />
          <Route path="checkout" element={<Checkout />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
