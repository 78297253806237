import { Fragment, useContext } from "react";
import { Outlet } from "react-router-dom";

import HomeImg from "../../assets/homepage-header-top.jpg";

import { HeaderContainer, HeaderImg,  Title, TitleContainer, BannerContainer } from "./navigation.styles";

import CartIcon from "../../components/cart-icon/cart-icon.component";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown.component";

import { ReactComponent as CrownLogo } from "../../assets/crown.svg";
import { UserContext } from "../../contexts/user.context";
import { CartContext } from "../../contexts/cart.context";
import { SignOutUser } from "../../utils/firebase/firebase.utils";

import {
  NavigationContainer,
  LogoContainer,
  NavLinks,
  NavLink,
} from "./navigation.styles";

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const { isCartOpen } = useContext(CartContext);

  return (
    <Fragment>
      <BannerContainer>
        <a href="#onsale">do not miss on sale items!</a>
      </BannerContainer>
      <HeaderContainer>
        <HeaderImg src={HomeImg} alt="home" />
        <TitleContainer>
          <Title>Sisteme de securitate complete</Title>
        </TitleContainer>
      </HeaderContainer>
      {/* <NavigationContainer>
        <LogoContainer to="/">
          <CrownLogo className="logo" />
        </LogoContainer>
        <NavLinks>
          <NavLink to="/shop">Shop</NavLink>
          {currentUser ? (
            <NavLink as='span' onClick={SignOutUser}>Sign Out</NavLink>
          ) : (
            <NavLink to="/auth">Sign In</NavLink>
          )}
          <CartIcon />
        </NavLinks>
        {isCartOpen && <CartDropdown />}
      </NavigationContainer> */}
      
      

    </Fragment>
  );
};

export default Navigation;
