import { Outlet } from "react-router-dom";
import { GeneralContainer, RightContainer, LeftContainer } from "./home.style";

import Directory from "../../components/directory/directory.component";
import ProductCard from "../../components/product-card/product-card.component";
import  accessControlImage  from "../../assets/camera-video.jpg";


import Footer from "../footer/footer.component";
import { Title } from "./home.style";


const Home = () => {
  return (
    <GeneralContainer>
      <LeftContainer>
        <Directory />
      </LeftContainer>
      <RightContainer>
        <h2 id="onsale">On Sale</h2>

        <ProductCard product={{ name: "Video Surveillance", price: "10000", imageUrl: accessControlImage }} />
        <br />
        <hr />
        <br />
        <ProductCard product={{ name: "Video Surveillance", price: "10000", imageUrl: accessControlImage }} />
        <br />
      </RightContainer>
      

      <Outlet />

    </GeneralContainer>
  );
};

export default Home;
