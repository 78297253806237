import DirectoryItem from "../directory-item/directory-item.component";

import SistemeVideo from "../../assets/sisteme-video.jpg";
import SistemeDetectie from "../../assets/fire-detection.jpg";
import SistemeAntiefractie from "../../assets/antiefractie.jpg";
import SistemeAccesControl from "../../assets/access-control.jpg";
import SistemeInterfonie from "../../assets/interfonie.jpg";
import SistemeAutomatizari from "../../assets/sisteme-automatizari.jpg";

import { DirectoryContainer } from "./directory.styles";

const categories = [
  {
    id: 1,
    title: "sisteme de supraveghere video",
    imageUrl: SistemeVideo,
    route: 'shop/hats'
  },
  {
    id: 2,
    title: "sisteme de detectie si alarmare la incendiu",
    imageUrl: SistemeDetectie,
    route: 'shop/jackets'

  },
  {
    id: 3,
    title: "sisteme de alarma antiefractie",
    imageUrl: SistemeAntiefractie,
    route: 'shop/sneakers'

  },
  {
    id: 4,
    title: "sisteme de control access",
    imageUrl: SistemeAccesControl,
    route: 'shop/womens'

  },
  {
    id: 5,
    title: "sisteme de interfonie, videointerfonie",
    imageUrl: SistemeInterfonie,
    route: 'shop/mens'

  },
  {
    id: 6,
    title: "automatizari porti, bariere auto",
    imageUrl: SistemeAutomatizari,
    route: 'shop/mens'

  },
];

const Directory = () => {
  return (
    <>


      <DirectoryContainer>
        {categories.map((category, index) => (
          <DirectoryItem key={index} category={category}
           size={index%3===0|| index%3===1 ? "35%":"50%"}/>
        ))}
      </DirectoryContainer>
    </>
  );
};
export default Directory;
